/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-04-05",
    versionChannel: "nightly",
    buildDate: "2025-04-05T00:06:40.082Z",
    commitHash: "0c72ed3415a9a55c074abb77bf00e700cdeae5a1",
};
